/* eslint-disable eqeqeq */
import React, { useState } from "react";
import "./HomeBanner.css";
import video from "../../../assests/sample.mp4";
import How from "../../../assests/how.png";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  const dispatch = useDispatch();
  // const [isPlay, setIsPlay] = useState(false);
  // const videoHandler = (e) => {
  //   const vid = document.getElementById(e);
  //   vid.play();
  //   console.log(vid);
  //   setIsPlay(!isPlay);
  // };
  // const videoHandlerPause = (e) => {
  //   const vid = document.getElementById("homeBanner");
  //   vid.pause();
  //   console.log(vid);
  //   setIsPlay(!isPlay);
  // };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const ScheduleCallHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };
  console.log(ScheduleCallHandler);
  return (
    <div>
      <div className="homebanner">
        <div className="homebannerFlex">
          <div className="homebannerFlexLeft">
            <div className="homebannerFlexLeftInner">
              <div className="homebannerFlexLeftHead">
                <h1>
                  HELLO AND WELCOME TO THE{" "}
                  <span className="headSpan">TRANCE</span>
                </h1>
              </div>
              <p>
                To help, To heal, To educate people, the ultimate challenge We
                are devoted to promoting mental wellbeing through online
                counseling, psychoeducation and community support, therapies.
                Our team of certified psychologists is dedicated to creating a
                safe space for healing and personal growth. Explore our
                resources, join our programs, and embark on a journey towards
                mental wellness with us. Together, let’s break down barriers and
                a resilient and thriving community.
              </p>
              {/* <div className="certificate">
                <h2>We are certified & recognised by</h2>
                <div className="certificateImg">
                  <img
                    src="https://www.themoodspace.com/Assets/LandingPage/ISO27001CompliantLogo.png"
                    alt=""
                  />
                  <img
                    src="https://www.themoodspace.com/Assets/LandingPage/HippaCompliantLogo.png"
                    alt=""
                  />
                  <img
                    src="https://www.themoodspace.com/Assets/LandingPage/GDPR-logo.png"
                    alt=""
                  />
                </div>
              </div> */}
              {/* <div className="homebannerFlexLeftFooter">
                <div className="homebannerFlexLeftFoot">
                  <h5>Privacy Assurance</h5>
                  <h6>privacy isn’t just a promise, it's our commitment .</h6>
                </div>
                <div className="homebannerFlexLeftFoot">
                  <h5>Timely therapy</h5>
                  <h6>
                    Counseling is available whenever it is convenient for you;
                    select a time that works for you
                  </h6>
                </div>
                <div className="homebannerFlexLeftFoot">
                  <h5>Flexible price</h5>
                  <h6>
                    Adaptable pricing designed to suit your budget and need
                  </h6>
                </div>
                <div className="homebannerFlexLeftFoot">
                  <h5>Experienced therapist</h5>
                  <h6>
                    experienced therapist prepared to guide you on the road to
                    success
                  </h6>
                </div>
              </div> */}
              <div className="homeBannerButton">
                <button
                  id="call"
                  onClick={ScheduleCallHandler}
                  className="callButton"
                >
                  Schedule Call
                </button>
                <a href="https://wa.me/917868801278?text=How can i help you ?">
                  <FaWhatsapp className="whatsAppIconBanner" />
                </a>
                <a href="tel:+917868801278">
                  <IoMdCall className="callIconBanner" />
                </a>
              </div>
            </div>
          </div>
          <div className="homebannerFlexRight">
            <div className="homebannerFlexRightImg">
              {/* {!isPlay && <div id="overlayHero"></div>} */}
              {/* <ReactPlayer url={video} /> */}

              <video
                width="320"
                height="240"
                id="homeBanner"
                controls={true}
                playsInline={false}
              >
                <source src={video} type="video/mp4"></source>
              </video>
              {/* <div className="pause">
                {isPlay == true ? (
                  <FaPauseCircle
                    id="homeBanner"
                    onClick={() => videoHandlerPause("homeBanner")}
                  />
                ) : (
                  <div>
                    <FaPlayCircle
                      id="homeBanner"
                      onClick={() => videoHandler("homeBanner")}
                    />
                  </div>
                )}
              </div> */}
              <img className="HowItWorks" src={How} alt="" />
              <div className="bannerShade"></div>
            </div>
            <div className="Social">
              <Link to="https://www.facebook.com/profile.php?id=61555821675045">
                <FaFacebookF />
              </Link>
              <Link to="https://youtube.com/@trance-admin?si=ym6i2BE8zBLGi0sF">
                <FaYoutube />
              </Link>
              <Link to="https://www.linkedin.com/company/trance-groups/">
                <FaLinkedinIn />
              </Link>
              <Link to="https://www.instagram.com/the._.trance_/?utm_source=ig_web_button_share_sheet">
                <FaInstagram />
              </Link>
            </div>
          </div>
        </div>
        <div className="homebannerFlexLeftFooter">
          <div className="homebannerFlexLeftFoot">
            <h5>Privacy Assurance</h5>
            <h6>Privacy isn’t just a promise, it's our commitment .</h6>
          </div>
          <div className="homebannerFlexLeftFoot">
            <h5>Timely therapy</h5>
            <h6>
              Counseling is available whenever it is convenient for you; select
              a time that works for you
            </h6>
          </div>
          <div className="homebannerFlexLeftFoot">
            <h5>Flexible price</h5>
            <h6>Adaptable pricing designed to suit your budget and need</h6>
          </div>
          <div className="homebannerFlexLeftFoot">
            <h5>Experienced therapist</h5>
            <h6>
              Experienced therapist prepared to guide you on the road to success
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
