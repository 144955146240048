import React, { useState } from "react";
import "./HomePentGrid.css";
import family from "../../../assests/bentoGrid.png";
import logo from "../../../assests/butterflyLogo.svg";
import review from "../../../assests/review.png";
import arrow from "../../../assests/reviewArrow.svg";
import { FaStar } from "react-icons/fa";

const HomePentGrid = () => {
  const [isHover, setIsHover] = useState("");
  const HoverHandler = (e) => {
    setIsHover(e.target.id);
  };
  const exitHoverHandler = () => {
    setIsHover(null);
  };
  return (
    <div>
      <div className="homePentGrid sectionWidth">
        <div className="bentoGrid">
          <div className="bentGrid1">
            <h1>Lorem Ipsum</h1>
            <p>
              I took 4 session at Trance, the therapist was very friendly and
              easy to approach anytime, i found a positive experience and
              effective changes in my anxiety issues. I highly recommend their
              services.
            </p>
            <div className="bentGridImg">
              <img src={family} alt="" />
            </div>
          </div>
          <div className="bentGrid2">
            <div className="">
              <h1>Lorem Ipsum</h1>
              <p>
                I came to Trance on my partner's recommendation to manage my
                anger and work stress, which were affecting our relationship.
                The therapist listened, analyzed my issues, and provided
                effective techniques to help me relax and balance work and
                personal life. Their approach was professional, supportive, and
                affordable.
              </p>
            </div>
            <div className="bentGrid2Icon">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
          </div>
          <div className="bentGrid3">
            <h1>Lorem Ipsum</h1>
            <p>
              I struggled with sleep issues that affected my daily life, but
              after a few therapy sessions at Trance, I saw positive
              improvements. Their guidance helped me regain my sleep and
              confidence. I highly recommend Trance for anyone facing similar
              problems.
            </p>
            <div className="bentGridImg">
              <img src={family} alt="" />
            </div>
          </div>
          <div className="bentGrid4">
            <div className="">
              <h1>Lorem Ipsum</h1>
              <p>
                Trance has helped me in sorting my relationship issues, they
                helped me in unlocking my thoughts and making proper decisions
                about my relationship and helped me with improving my mental
                health with positive outcomes. I will be forever grateful to
                trance for making me strong and positive person
              </p>
            </div>
            <div className="bentGrid2Icon">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
          </div>
          <div className="bentGrid5">
            <img src={logo} alt="" />
            <h1>Testimonials</h1>
            <p>
              Real stories from those who found healing through our services.
              Hear how their lives transformed with the right support.
            </p>
          </div>
          <div className="bentGrid6">
            <div className="review">
              <div className="">
                <img
                  id="rev1"
                  onMouseEnter={HoverHandler}
                  onMouseLeave={exitHoverHandler}
                  className="reviewImg1"
                  src={review}
                  alt=""
                />
                <div className="">
                  {isHover === "rev1" && (
                    <>
                      <img className="arrow" src={arrow} alt="" />
                      <h6 className="reviewHover">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </h6>
                    </>
                  )}
                </div>
              </div>
              <img
                id="rev2"
                onMouseEnter={HoverHandler}
                onMouseLeave={exitHoverHandler}
                className="reviewImg2"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev2" && (
                  <>
                    <img className="arrow2" src={arrow} alt="" />
                    <h6 className="reviewHover2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </h6>
                  </>
                )}
              </div>
              <img
                id="rev3"
                onMouseEnter={HoverHandler}
                onMouseLeave={exitHoverHandler}
                className="reviewImg3"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev3" && (
                  <>
                    <img className="arrow3" src={arrow} alt="" />
                    <h6 className="reviewHover3">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </h6>
                  </>
                )}
              </div>
              <img
                id="rev4"
                onMouseEnter={HoverHandler}
                onMouseLeave={exitHoverHandler}
                className="reviewImg4"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev4" && (
                  <>
                    <img className="arrow4" src={arrow} alt="" />
                    <h6 className="reviewHover4">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </h6>
                  </>
                )}
              </div>
              <img
                id="rev5"
                onMouseEnter={HoverHandler}
                onMouseLeave={exitHoverHandler}
                className="reviewImg5"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev5" && (
                  <>
                    <img className="arrow5" src={arrow} alt="" />
                    <h6 className="reviewHover5">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </h6>
                  </>
                )}
              </div>
              <img
                id="rev6"
                onMouseEnter={HoverHandler}
                onMouseLeave={exitHoverHandler}
                className="reviewImg6"
                src={review}
                alt=""
              />
              <div className="">
                {isHover === "rev6" && (
                  <>
                    <img className="arrow6" src={arrow} alt="" />
                    <h6 className="reviewHover6">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industr.
                    </h6>
                  </>
                )}
              </div>
            </div>
            <div className="border">
              <h1>Lorem Ipsum</h1>
              <p>
                I approached Trance with a heavy heart, struggling with negative
                thoughts and low self-confidence due to past trauma. Thanks to
                their patient and supportive approach, therapy, and helpful
                activities, I now feel more confident, strong, and energetic.
                Trance truly transformed my life, and I highly recommend their
                services to anyone facing challenges.
              </p>
              <div className="bentGridImg">
                <img src={family} alt="" />
              </div>
            </div>
          </div>
          <div className="bentGrid7">
            <div className="">
              <h1>Lorem Ipsum</h1>
              <p>
                I reached out to Trance during a tough time after losing someone
                close to me. Through nine therapy sessions, they helped me
                accept my loss, regain my strength, and rebuild my life.
                Initially unsure about therapy, they patiently explained the
                process and made me comfortable. I’m grateful for the
                transformation and would confidently recommend Trance to anyone
                going through a difficult time.
              </p>
            </div>
            <div className="bentGrid2Icon">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
          </div>
          <div className="bentGrid8">
            <div className="">
              <h1>Lorem Ipsum</h1>
              <p>
                Trance transformed my life from a low point to a happy,
                productive state. After trying other therapists with little
                success, Trance's friendly, professional, and affordable
                approach made a real difference. They truly care about their
                clients' progress.
              </p>
            </div>
            <div className="bentGrid2Icon">
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
              <FaStar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePentGrid;
