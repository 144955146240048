import React from "react";
import "./Footer.css";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <div className="">
        <div className="footer sectionWidth ">
          <div className="innerFooter">
            <div className="foot1">
              <h1>Visit us in Coimbatore.</h1>
              <p>
                L75A, First floor, Srinagar, B.R.Puram, Peelamedu, Hope College,
                Coimbatore.
              </p>
              <p>Tamil Nadu 641004</p>
            </div>
            <div className="foot2">
              <h1>Website Map</h1>
              <div className="footGrid">
                <p>Home</p>
                <p>About Us</p>
                <p>Blog</p>
                <p>Contact Us</p>
              </div>
            </div>
            <div className="foot3">
              <h1>Contact</h1>
              <div className="footGrid">
                <p>
                  <a href="mailto:info@trancegroups.com">
                    {" "}
                    info@trancegroups.com
                  </a>
                </p>
                <p>
                  <a href="tel:+918438656432">IN: +91 8438656432</a>
                </p>
                <p>
                  <a href="https://trancegroups.com/">
                    https://trancegroups.com
                  </a>
                </p>
                <p>Lorem ipsum</p>
              </div>
            </div>
            <div className="foot4">
              {/* <div className="SocialMob"> */}
              <a href="tel:+917868801278">
                <IoMdCall />
              </a>
              <a href="https://wa.me/917868801278?text=How can i help you ?">
                <FaWhatsapp />
              </a>
              <Link to="https://www.facebook.com/profile.php?id=61555821675045">
                <FaFacebookF />
              </Link>
              <Link to="https://youtube.com/@trance-admin?si=ym6i2BE8zBLGi0sF">
                <FaYoutube />
              </Link>
              <Link to="https://www.linkedin.com/company/trance-groups/">
                <FaLinkedinIn />
              </Link>
              <Link to="https://www.instagram.com/the._.trance_/?utm_source=ig_web_button_share_sheet">
                <FaInstagram />
              </Link>
              {/* </div> */}
            </div>
          </div>
          <h3>© All rights reserved</h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;
