import React, { useEffect } from "react";
import "./HomeGrid.css";
import couple from "../../../assests/couple.png";
import individual from "../../../assests/individual.png";
import group from "../../../assests/group.png";
import student from "../../../assests/student.png";
import cardTwoImg from "../../../assests/gridTwo.png";
import CardOne from "../../Reusable/CardOne/CardOne";
import Heading from "../../Reusable/Heading/Heading";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../Store/Store";
import AOS from "aos";
import "aos/dist/aos.css";

const HomeGrid = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  // const isPopUp = useSelector((store) => {
  //   return store.isPopUp;
  // });

  const ScheduleCallHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const dispatch = useDispatch();
  return (
    <div>
      <div className="homeGrid sectionWidth">
        <Heading
          headClass='heading txtColor'
          Head="Personalized Therapy Services Just For You"
          desc="“Unlock your potential with our tailored therapy services. Our compassionate counselors provide personalized support, guiding you through lifes challenges and transition. Whether it’s improving relationships or navigating personal growth, were here to empower you on your journey to greater well-being.”"
        />
        <div className="gridOne">
          <CardOne
            Heading="Individual therapy"
            description="Provides individualized help and direction to explore feelings, manage mental health issues, and create a support system in a private and encouraging setting."
            button="Enquire Now"
            descClass="cardOnePara"
            classImage="cardOneImg"
            image={individual}
            fun1={ScheduleCallHandler}
          />
          <CardOne
            Heading="Couple therapy"
            description="Improve communication, settle arguments, and create greater connections in your relationship with our skilled couple therapy sessions. Find a way to restore understanding and communication now."
            button="Enquire Now"
            descClass="cardOnePara"
            classImage="cardOneImg"
            image={couple}
          />
        </div>
        <div className="gridTwo">
          <div data-aos="fade-up" className="mobileHandler ">
            <CardOne
              Heading="Individual therapy"
              description="Provides individualized help and direction to explore feelings, manage mental health issues, and create a support system in a private and encouraging setting."
              button="Enquire Now"
              descClass="cardOneDesc"
              // classImage="webHandler"
              classImage="cardTwoImg"
              // image={cardTwoImg}
              image={individual}
            />
          </div>
          <div data-aos="fade-up" className="mobileHandler">
            <CardOne
              Heading="Couple therapy"
              description="Improve communication, settle arguments, and create greater connections in your relationship with our skilled couple therapy sessions. Find a way to restore understanding and communication now."
              button="Enquire Now"
              descClass="cardOneDesc"
              // classImage="webHandler"
              classImage="cardTwoImg"
              // image={cardTwoImg}
              image={couple}
            />
          </div>
          <div className="webHandler">
            <CardOne
              Heading="Family counseling"
              description="Overcomes obstacles, enhances relationships, and promotes better communication for resilient, peacefulness and improved well-being."
              button="Enquire Now"
              descClass="cardOneDesc"
              classImage="cardTwoImg"
              image={cardTwoImg}
            />
          </div>
          <div className="webHandler">
            <CardOne
              Heading="Students counseling"
              description="Provides private support for various struggles, addressing stress, relationships, academic performance and personal growth."
              button="Enquire Now"
              descClass="cardOneDesc"
              classImage="cardTwoImg"
              image={student}
            />
          </div>
          <div className="webHandler">
            <CardOne
              Heading="Organizational counseling"
              description="Support for businesses, improving work environment, productivity, conflict, encouraging resilience, and implementing positive change for success."
              button="Enquire Now"
              descClass="cardOneDesc"
              classImage="cardTwoImg2"
              image={group}
            />
          </div>
          <div data-aos="fade-up" className="mobileHandler">
            <CardOne
              Heading="Family counseling"
              description="Overcomes obstacles, enhances relationships, and promotes better communication for resilient, peacefulness and improved well-being."
              button="Enquire Now"
              descClass="cardOneDesc"
              // classImage="webHandler"
              classImage="cardTwoImg"
              image={cardTwoImg}
            />
          </div>
          <div data-aos="fade-up" className="mobileHandler">
            <CardOne
              Heading="Students counseling"
              description="Provides private support for various struggles, addressing stress, relationships, academic performance and personal growth."
              button="Enquire Now"
              descClass="cardOneDesc"
              // classImage="webHandler"
              classImage="cardTwoImg"
              image={student}
            />
          </div>
          <div data-aos="fade-up" className="mobileHandler">
            <CardOne
              Heading="Organizational counseling"
              description="Support for businesses, improving work environment, productivity, conflict, encouraging resilience, and implementing positive change for success."
              button="Enquire Now"
              descClass="cardOneDesc"
              // classImage="webHandler"
              classImage="cardTwoImg"
              image={group}
              // image={cardTwoImg}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeGrid;
